<template>
    <div>
        <hr class="mt-0 mb-1">
        <b-row align-h="start">
            <b-col md="4" class="d-flex align-items-center">{{
                $moment(form.created_at).format('[Creada en] MMMM DD YYYY') }}</b-col>
        </b-row>
        <hr class="my-1">

        <b-row class="mt-2">
            <b-col class="font-weight-bold">
                Especie
            </b-col>
            <b-col>
                {{ form.specie_name }}
            </b-col>

            <b-col class="font-weight-bold">
                Empaque
            </b-col>
            <b-col>
                {{ form.packaging_name }}
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col class="font-weight-bold">
                Altura
            </b-col>
            <b-col>
                {{ form.height }}
            </b-col>

            <b-col class="font-weight-bold">
                Dias de produccion
            </b-col>
            <b-col>
                {{ form.production_days }}
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6" class="font-weight-bold">
                Descripción
            </b-col>

        </b-row>

        <b-row class="mt-1">
            <b-col cols="6">
                {{ form.description }}
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-table :items="items.consolidated" :fields="fields.consolidated" small :busy="loads.consolidated">
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                    </div>
                </template>
                <template #thead-top="data">
                    <b-tr>
                        <b-th class="bg-muted" colspan="2">
                            <b-row align-v="start" class="pl-1">
                                <b-col lg="1/4">
                                    <feather-icon icon="CheckSquareIcon" size="16" />
                                </b-col>
                                <b-col>
                                    Inventario consolidado
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                </template>
            </b-table>
            <b-row align-v="end">
                <b-col lg="2">
                    <b-pagination v-model="params.consolidated.current_page" :total-rows="totals.consolidated"
                        :per-page="params.consolidated.per_page" aria-controls="my-table"></b-pagination>
                </b-col>
            </b-row>
        </b-row>

        <b-row class="mt-3">
            <b-table :items="items.detailed" :fields="fields.detailed" small>
                <template #thead-top="data">
                    <b-tr>
                        <b-th class="bg-muted" colspan="6">
                            <b-row align-v="start" class="pl-1">
                                <b-col lg="1/4">
                                    <feather-icon icon="CheckSquareIcon" size="16" />
                                </b-col>
                                <b-col>
                                    Inventario detallado
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-th class="bg-white" colspan="6">
                            <b-row>
                                <b-col cols="6">
                                    <b-form-group class="w-100">
                                        <label for="nursery_id" class="text-success">Vivero</label>
                                        <b-form-select id="nursery_id" class="fl" v-model="params.detailed.nursery_id"
                                            value-field="id" text-field="name" :options="list.nurseries"
                                            @change="params.detailed.current_page === 1 ? getData('detailed', params.detailed, true) : params.detailed.current_page = 1"
                                            name="nursery_id"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                </template>
            </b-table>
            <b-row align-v="end">
                <b-col lg="2">
                    <b-pagination v-model="params.detailed.current_page" :total-rows="totals.detailed"
                        :per-page="params.detailed.per_page" aria-controls="my-table"></b-pagination>
                </b-col>
            </b-row>
        </b-row>

        <b-row class="mt-3">
            <b-table :items="items.adjustment" :fields="fields.adjustment" small>
                <template #thead-top="data">
                    <b-tr>
                        <b-th class="bg-muted" colspan="7">
                            <b-row align-v="start" class="pl-1">
                                <b-col lg="6">
                                    <b-row align-v="start" class="p-1">
                                        <b-col lg="1/4">
                                            <feather-icon icon="CheckSquareIcon" size="16" />
                                        </b-col>
                                        <b-col>
                                            Ajustar inventario
                                        </b-col>
                                    </b-row>
                                </b-col>

                                <b-col lg="6">
                                    <b-row align-v="start" class="pr-4 d-flex flex-column align-items-end">
                                        <b-dropdown size="sm" variant="link" no-caret>
                                            <template #button-content>
                                                <b-button size="sm" variant="primary">
                                                    <feather-icon icon="PlusIcon" />
                                                    <span class="align-middle">Ajustar</span>
                                                </b-button>
                                            </template>
                                            <b-dropdown-item class="b-d-i" :href="`/gestion-productos/adjustment/addition/${form.id}`">Ajustar adicion</b-dropdown-item>
                                            <b-dropdown-item class="b-d-i" :href="`/gestion-productos/adjustment/loss/${form.id}`">Ajustar perdidas</b-dropdown-item>
                                            <b-dropdown-item class="b-d-i" :href="`/gestion-productos/adjustment/elimination/${form.id}`">Ajustar eliminacion</b-dropdown-item>
                                            <b-dropdown-item class="b-d-i" :href="`/gestion-productos/adjustment/seedling/${form.id}`">Mover a planton</b-dropdown-item>
                                            <b-dropdown-item class="b-d-i" :href="`/gestion-productos/adjustment/available/${form.id}`">Mover a disponible</b-dropdown-item>
                                        </b-dropdown>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                </template>
            </b-table>
            <b-row align-v="end">
                <b-col lg="2">
                    <b-pagination v-model="params.adjustment.current_page" :total-rows="totals.adjustment"
                        :per-page="params.adjustment.per_page" aria-controls="my-table"></b-pagination>
                </b-col>
            </b-row>
        </b-row>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: {
                id: '',
                specie_name: '',
                packaging_name: 0,
                height: 0,
                production_days: 0,
                description: '',
            },
            items: {
                consolidated: [],
                detailed: [],
                adjustment: [],
            },
            loads: {
                consolidated: true,
                detailed: true,
                adjustment: true,
            },
            per_page: 5,
            params: {
                consolidated: {
                    current_page: 1,
                    per_page: 5,
                },
                detailed: {
                    current_page: 1,
                    per_page: 5,
                    nursery_id: null,
                },
                adjustment: {
                    current_page: 1,
                    per_page: 5,
                },
            },
            totals: {
                consolidated: 0,
                detailed: 0,
                adjustment: 0,
            },

            list: {
                nurseries: [],
            },
            search: {
                nursery_id: '',
            },

            fields: {
                consolidated: [
                    {
                        key: 'nursery_name',
                        label: 'Vivero',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'quantity',
                        label: 'En produccion',
                        formatter: (value) => this.formatNumber(parseInt(value)),
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                ],
                detailed: [
                    {
                        key: 'nursery_name',
                        label: 'Vivero',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'quantity',
                        label: 'Disponible',
                        formatter: (value) => this.formatNumber(parseInt(value)),
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                
                    {
                        key: 'planting_date',
                        label: 'Fecha siembra',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'days_missing',
                        label: 'Dias faltantes',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },

                ],
                adjustment: [
                    {
                        key: 'created_at',
                        label: 'Fecha',
                        formatter: (value) => this.$moment(value).format('DD/MM/YYYY'),
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'adjustment_type',
                        label: 'Tipo',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'nursery_name',
                        label: 'Vivero',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'quantity',
                        label: 'Cantidad',
                        formatter: (value) => this.formatNumber(parseInt(value)),
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                    {
                        key: 'user_name',
                        label: 'Usuario',
                        thStyle: {
                            backgroundColor: 'transparent'
                        }
                    },
                ],
            },
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        async 'params.consolidated.current_page'(new_value, old_value) {
            await this.getData('consolidated', this.params.adjustment, true);
        },

        async 'params.detailed.current_page'(new_value, old_value) {
            await this.getData('detailed', this.params.adjustment, true);
        },

        async 'params.adjustment.current_page'(new_value, old_value) {
            await this.getData('adjustment', this.params.adjustment, true);
        },

    },

    methods: {
        async init() {
            this.form.id = this.$route.params.id;
            await this.getPermission();
            await this.get();
        },

        async get() {
            try {
                this.loading();
                this.form = (await this.$http.get(`production/product/show/${this.form.id}`)).data.data;
                this.list.nurseries = (await this.$http.get(`production/product/index-nursery/${this.form.id}`)).data.data;
                await this.getData('consolidated', this.params.consolidated);
                await this.getData('detailed', this.params.detailed);
                await this.getData('adjustment', this.params.adjustment);
                this.setHeaderTitle(`Id. ${this.form.id} / ${this.form.specie_name}`);
                this.setHeaderSubTitle(this.form.packaging_name);
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push({ name: 'gestion-productos' })
                }
                this.notify("Error", err.response.data.message, 'danger')
                console.log(err);
            } finally {
                this.not_loading();
            }
        },

        async getData(type, parameters, load = false) {
            try {
                if (load) this.loading();
                this.loads[type] = true;
                const { data, total } = (await this.$http
                    .get(`production/product/index-${type}/${this.form.id}`,
                        { params: this.params[type] }
                    )).data
                this.items[type] = data;
                this.totals[type] = total;
            } catch (err) {
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.loads[type] = false;
                if (load) this.not_loading();
            }
        },

        async getAdjustment(load = false) {
            try {
                if (load) this.loading();
                this.loads.adjustment = true;
                const p = this.params.adjustment;
                const { data, total } = (await this.$http
                    .get(`production/product/index-adjustment/${this.form.id}`,
                        { params: p }
                    )).data
                this.items.adjustment = data;
                this.totals.adjustment = total;
            } catch (err) {
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.loads.adjustment = false;
                if (load) this.not_loading();
            }
        },

    },
}
</script>

<style scoped>
.b-d-i {
    text-transform: none !important;
}
</style>